import { buildSchema } from "@camberi/firecms";

export type Lead = {
  company: string;
  description: string;
  email: string;
  name: string;
  phone: string;
};

export const LeadSchema = buildSchema<Lead>({
  name: "Lead",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    email: {
      title: "Email",
      validation: { required: true, email: true },
      dataType: "string",
    },
    phone: {
      title: "Phone",
      validation: { required: true },
      dataType: "string",
    },
    company: {
      title: "Company",
      validation: { required: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      description: "Not mandatory but it'd be awesome if you filled this up",
      longDescription:
        "Example of a long description hidden under a tooltip. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis bibendum turpis. Sed scelerisque ligula nec nisi pellentesque, eget viverra lorem facilisis. Praesent a lectus ac ipsum tincidunt posuere vitae non risus. In eu feugiat massa. Sed eu est non velit facilisis facilisis vitae eget ante. Nunc ut malesuada erat. Nullam sagittis bibendum porta. Maecenas vitae interdum sapien, ut aliquet risus. Donec aliquet, turpis finibus aliquet bibendum, tellus dui porttitor quam, quis pellentesque tellus libero non urna. Vestibulum maximus pharetra congue. Suspendisse aliquam congue quam, sed bibendum turpis. Aliquam eu enim ligula. Nam vel magna ut urna cursus sagittis. Suspendisse a nisi ac justo ornare tempor vel eu eros.",
      dataType: "string",
      config: {
        multiline: true,
      },
      columnWidth: 300,
    },
  },
});
