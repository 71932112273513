import { User as FirebaseUser } from "firebase/auth";
import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import { LeadSchema } from "./schemas/lead";
import { UserSchema } from "./schemas/user";
import { TechSchema } from "./schemas/tech";
import { JobSchema } from "./schemas/job";
import { JobSubmissionSchema } from "./schemas/job-submission";

const firebaseConfig = {
  apiKey: "AIzaSyCPJQryliXjbkGseJMi12UwNp5b96K9cz0",
  authDomain: "softaware-io.firebaseapp.com",
  projectId: "softaware-io",
  storageBucket: "softaware-io.appspot.com",
  messagingSenderId: "1036228599177",
  appId: "1:1036228599177:web:640e47fc17be23c9a75558",
};

const locales = {
  "en-US": "English (United States)",
};

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    return {
      collections: [
        buildCollection({
          path: "leads",
          schema: LeadSchema,
          name: "Leads",
          permissions: ({ authController }) => ({
            read: authController.extra.roles["admin"],
            edit: authController.extra.roles["admin"],
            create: authController.extra.roles["admin"],
            delete: authController.extra.roles["admin"],
          }),
          subcollections: [],
        }),
        buildCollection({
          path: "tech",
          schema: TechSchema,
          name: "Tech",
          permissions: ({ authController }) => ({
            read: authController.extra.roles["admin"],
            edit: authController.extra.roles["admin"],
            create: authController.extra.roles["admin"],
            delete: authController.extra.roles["admin"],
          }),
          subcollections: [],
        }),
        buildCollection({
          path: "jobs",
          schema: JobSchema,
          name: "Jobs",
          permissions: ({ authController }) => ({
            read: authController.extra.roles["admin"],
            edit: authController.extra.roles["admin"],
            create: authController.extra.roles["admin"],
            delete: authController.extra.roles["admin"],
          }),
          subcollections: [],
        }),
        buildCollection({
          path: "jobSubmissions",
          schema: JobSubmissionSchema,
          name: "Job Submissions",
          permissions: ({ authController }) => ({
            read: authController.extra.roles["admin"],
            edit: authController.extra.roles["admin"],
            create: authController.extra.roles["admin"],
            delete: authController.extra.roles["admin"],
          }),
          subcollections: [],
        }),
      ],
    };
  };

  const authenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController,
    dataSource,
  }) => {
    const fetchedUser = await dataSource.fetchEntity({
      entityId: user?.uid as string,
      schema: UserSchema,
      path: "users",
    });

    authController.setExtra(fetchedUser.values);

    return true;
  };

  return (
    <FirebaseCMSApp
      name="Softaware Admin"
      firebaseConfig={firebaseConfig}
      authentication={authenticator}
      navigation={navigation}
      logo="/logo.svg"
      primaryColor="#3b3a57"
      toolbarExtraWidget
    />
  );
}
