import { buildProperty, buildSchema } from "@camberi/firecms";

export type Tech = {
  name: string;
  image: string;
};

export const TechSchema = buildSchema<Tech>({
  name: "Tech",
  properties: {
    name: {
      title: "Name",
      dataType: "string",
    },
    image: buildProperty({
      title: "Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "tech",
          acceptedFiles: ["image/svg+xml"],
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
  },
});
