import { buildSchema } from "@camberi/firecms";

export type User = {
  roles: Map<string, boolean>;
};

export const UserSchema = buildSchema<User>({
  name: "User",
  properties: {
    roles: {
      title: "Roles",
      dataType: "map",
    },
  },
});
