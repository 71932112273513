import { buildProperty, buildSchema, EntityReference } from "@camberi/firecms";

export type JobSubmission = {
  cv: string;
  job: EntityReference;
  email: string;
  motivation: string;
  name: string;
  phone: string;
};

export const JobSubmissionSchema = buildSchema<JobSubmission>({
  name: "JobSubmission",
  properties: {
    job: {
      dataType: "reference",
      title: "Submitted for Job",
      path: "jobs",
    },
    cv: buildProperty({
      title: "CV File",
      dataType: "string",
      config: {
        storageMeta: {
          storagePath: "cvs",
          acceptedFiles: [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ],
        },
      },
    }),
    email: {
      title: "Email",
      validation: { required: true, trim: true },
      dataType: "string",
    },
    motivation: {
      title: "Motivation",
      dataType: "string",
      validation: {
        trim: true,
        required: true,
      },
      config: {
        multiline: true,
      },
      columnWidth: 300,
    },
    name: {
      title: "Name",
      validation: { required: true, trim: true },
      dataType: "string",
    },
    phone: {
      title: "Phone",
      dataType: "string",
    },
  },
});
