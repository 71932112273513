import { buildSchema } from "@camberi/firecms";

export type Job = {
  title: string;
  description: string;
  location: string;
  remote?: boolean;
  tech: string[];
  active: boolean;
  slug: string;
};

export const JobSchema = buildSchema<Job>({
  name: "Job",
  properties: {
    title: {
      title: "Title",
      validation: { required: true, trim: true },
      dataType: "string",
    },
    description: {
      title: "Description",
      dataType: "string",
      validation: {
        trim: true,
        required: true,
      },
      config: {
        multiline: true,
        markdown: true,
      },
      columnWidth: 300,
    },
    slug: {
      title: "Slug",
      validation: { required: true, trim: true },
      dataType: "string",
    },
    location: {
      title: "Location",
      dataType: "string",
    },
    remote: {
      title: "Remote",
      dataType: "boolean",
    },
    active: {
      title: "Active",
      dataType: "boolean",
    },
    tech: {
      title: "Tech",
      dataType: "array",
      of: {
        dataType: "reference",
        path: "tech",
      },
    },
  },
});
